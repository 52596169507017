export const APP_STORAGE_KEY = '@cock-fight';

// weeks;
export const CURRENT_WEEK = 'CURRENT_WEEK';
export const LAST_WEEK = 'LAST_WEEK';
export const TREE_WEEKS_BACK = 'TREE_WEEKS_BACK';

// bet type
export const betType: Record<number, string> = {
  1: 'Directo',
  2: 'Parlay'
} as const;

export const betStatus: Record<number, string> = {
  1: 'Pendiente',
  2: 'Ganado',
  3: 'Perdido',
  4: 'Cancelado',
  5: 'Cerrado',
  6: 'Tabla'
} as const;

export const BET_TYPES = {
  parlay: 2,
  directo: 1
}
export * from './RoutesPaths';
