import { ReactComponent as Logo } from 'src/assets/logo.svg'

export function LoginFooter(): JSX.Element {
  return (
    <footer className='px-12 py-6 dark:bg-gray-900 flex-col flex md:flex-row justify-center items-center'>
      {/* copyright */}
      <div className='md:w-1/2'>
        <h1 className='text-white font-bold'>Copyright © Cock Fight {new Date().getFullYear()}</h1>
      </div>
      {/*  */}
      <div className='p-4 md:hidden' />
      {/* logo */}
      <div className='md:w-1/2'>
        <div className=' h-10 w-10 rounded-full'>
          <Logo className='w-full h-full'/>
        </div>
      </div>
    </footer>
  )
}
