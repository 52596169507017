import React, { useState } from 'react'
import BaseModal from '../BaseModal'
import { Button, CustomCheckbox } from 'src/components';
import { FaUser } from 'react-icons/fa';
import { useBalance } from 'src/store';
import { type PaginatedBase, useService } from 'src/hooks';
import { currency } from 'src/utils';
interface props {
  isOpen: boolean
  onClose: () => void
}

export function WithdrawModal({ isOpen, onClose }: props) {
  const [otherPerson, setOtherPerson] = useState<boolean>(false);
  const { balance } = useBalance()
  const [name, setName] = useState<string>('');
  const [cardId, setCardId] = useState<string>('');
  const [amount, setAmount] = useState<number>(0);
  const [balanceAvailable, setBalanceAvailable] = useState<number>(0);
  const rechargeBalances = useService<PaginatedBase<any[]>>({
    route: 'rechargeBalances',
    data: res => {
      if (res) {
        setName('')
        setCardId('')
        setAmount(0)
        setOtherPerson(false)
        onClose()
      }
    }
  });
  const postWithdraw = () => {
    const authorizedPerson = {
      name,
      cardId
    }
    rechargeBalances.post({
      data: {
        transactionType: 2,
        status: 1,
        transactionMethod: 1,
        amount: amount.toString(),
        authorizedPerson : otherPerson ? authorizedPerson : undefined
      }
    })
  }
  const enableButton = () => {
    if (otherPerson) {
      if (name && cardId && amount) {
        return false
      }
    } else {
      if (amount) {
        return false
      }
    }

    return true
  }
  React.useEffect(() => {
    if (balance) {
      setBalanceAvailable(balance.available)
    }
  }, [balance])
  return (
    <BaseModal isOpen={isOpen}>
    <div className="flex flex-col items-center">
    <div className="flex w-full items-center p-4 px-10">
    <h1 className="TitleTextModal w-2/4 lg:w-1/4 ">Retirar</h1>
  </div>
  <div className='w-full h-16 px-6 my-4'>
  <div className='card w-full h-full p-6 flxCenterBetween'>

    <span className='text-[#5A6F84]'>Monto disponible:</span>
    <span className='text-[#425A72] font-semibold'>{currency(balanceAvailable.toString())}</span>
  </div>
  </div>
  <div className='w-full h-16 px-6 flxCenterBetween my-4'>
    <span className='TitleText'> MONTO A RETIRAR |</span>
    <input type="number" className="bg-[#ECEFF2] rounded-lg p-2 w-1/3 text-right  " placeholder='0.00' value={amount} onChange={(e) => {
      const inputValue = e.target.valueAsNumber;

      if (inputValue <= balanceAvailable) {
        setAmount(inputValue);
      } else {
        if (inputValue < 0 || isNaN(inputValue)) {
          setAmount(0);
        } else {
          setAmount(balanceAvailable);
        }
      }
    }} max={balanceAvailable} min={0}/>
    </div>
     <div className='w-full h-16 px-6 flex items-center my-4'>
                <CustomCheckbox
                name={``}
                value={1}
                checked={otherPerson}
                classNames=' mx-4'
                checkColor='#181E5C'
                onChange={() => setOtherPerson(!otherPerson)}
                />
                <div className='flex flex-col'>
                  <span className='selectText'>Autorizar otra persona</span>
                  <span className='text-sm'>Autorizar que otra persona retire el dinero</span>
                </div>
      </div>
    {
      otherPerson &&
    <div className='w-full h-16 px-6 flxCenterBetween my-4'>
    <label className="flex flex-col w-1/2 mx-2">
                  <div className="relative">
                    <input
                      className="bg-[#F5F7F9] text-sm sm:text-base placeholder-gray-500 pl-2 pr-4 rounded-lg  w-full py-4 focus:outline-none focus:border-blue-400"
                      placeholder="Nombre"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <div className="inline-flex items-center justify-center absolute right-0 top-0 h-full w-10 text-gray-400">
                      <FaUser />
                    </div>
                  </div>
                </label>
    <label className="flex flex-col w-1/2 mx-2">
                  <div className="relative">
                    <input
                      className="bg-[#F5F7F9] text-sm sm:text-base placeholder-gray-500 pl-2 pr-4 rounded-lg  w-full py-4 focus:outline-none focus:border-red-400"
                      placeholder="Cedula"
                      value={cardId}
                      onChange={(e) => setCardId(e.target.value)}
                    />
                    <div className="inline-flex items-center justify-center absolute right-0 top-0 h-full w-10 text-gray-400">
                      <FaUser />
                    </div>
                  </div>
                </label>
    </div>
}
<div className="flex mt-4 mb-4 w-full justify-end">
        <button className=" text-[#404F60] px-4 py-4 rounded-lg mr-4" onClick={() => onClose()}>Cancelar</button>
        <Button classes="bg-[#181E5C] text-white px-4 py-4 rounded-lg mr-4" onClick={() => postWithdraw()} disabled={enableButton() || rechargeBalances.serviceLoading === 'post' } loading={rechargeBalances.serviceLoading === 'post'} label='CONFIRMAR'/>
      </div>
    </div>

  </BaseModal>
  )
}
