import { gql } from "@apollo/client";

export const LISTEN_TO_PLAYER_BALANCE = gql`
subscription ListenPlayerBalance($userId: Float!) {
    listenPlayerBalance(userId: $userId) {
      balance
      available
      pending
    }
  }
`;
export const LISTEN_TO_GAMES = gql`
subscription ListenGames {
  listenGames {
    betCouple
    createdAt
    fightNumber
    status
    id
    lessPercentage
    morePercentage
    moreTime
    lessTime
    startDate
    startTime
    updatedAt
    coliseum {
      id
      createdAt
      updatedAt
      name
      country
      city
      fights
    }
  }
  }
`;
export const LISTEN_TO_TODAY_GAMES = gql`
  subscription ListenTodayGames {
    listenTodayGames {
      id
      createdAt
      updatedAt
      startDate
      sync
      startTime
      betCouple
      status
      moreTime
      lessTime
      morePercentage
      lessPercentage
      fightNumber
      coliseum {
        id
        createdAt
        updatedAt
        name
        country
        city
        fights
      }
      fighters {
        id
        color
        sync
        weight
        brand
        scars
        betPercent
        isVeteran
        isWinner
        favorite
        createdAt
        updatedAt
        team {
          id
          createdAt
          updatedAt
          average
          name
        }
      }
    }
  }`;
export const LIST_TO_FIGHTERS = gql`
  subscription ListenFighters {
    listenFighters {
      favorite
      betPercent
      game {
        id
      }
      id
    }
  }
`;
