import { FaCalendar } from 'react-icons/fa';
import { betStatus } from 'src/utils';

interface Props {
  bet: BetDetails
  to: number
  amount: string
  date: string
}
export default function DetailItem({ bet, to, amount, date }: Props) {
  function generateHeader() {
    const [first, second] = bet.game.fighters;
    return <>
            {first.team.name}{' '}
            <span className='text-red-600 font-bold'>VS</span>{' '}
            {second.team.name}
        </>
  }

  function generateDate() {
    return <div className='flex items-center gap-2 text-[#404F60] font-bold text-sm'>
                <FaCalendar />{date}
        </div>
  }

  function generatePlayingAt(status: number) {
    if (bet.betType.id === 1) {
      const selectFighter = bet.fighter.id
      const fighterSelected = bet.game.fighters.find(item => item.id === selectFighter)
      return <p>
                <span className='font-bold text-[#404F60]'>Jugando a:</span>{' '}
                {fighterSelected?.team.name}{' '}
                <span className='font-bold'>({fighterSelected?.favorite ? '-' : '+'}{fighterSelected?.betPercent})</span>
            </p>
    } else {
      const maxTime = bet.maxTime ? bet.maxTime.toString().slice(3) : bet.minTime.toString().slice(3);
      return <p>
                <span className='font-bold text-[#404F60]'>Jugando a:</span>{' '}
                {bet.maxTime && maxTime ? `A ${maxTime}` : `B ${maxTime}`}{' '}
                <span className='font-bold'>(-{bet.game.lessPercentage})</span>
            </p>
    }
  }

  function generateType() {
    return <div className='flex items-end justify-between'>
            <p>
                <span className='font-bold text-[#404F60]'>Tipo:</span>{' '}
                {bet.betType.name}
            </p>
        </div>
  }
  function generateStatus() {
    return <div className='flex items-end justify-between'>
            <p>
                <span className='font-bold text-[#404F60]'>Estado:</span>{' '}
                {betStatus[bet.game.status]}
            </p>
        </div>
  }
  function generateWinner() {
    const winner = bet.game.fighters.find(item => item.isWinner)
    return <div className='flex items-end justify-between'>
                <p>
                    <span className='font-bold text-[#404F60]'>Ganador:</span>{' '}
                    {winner ? winner.team.name : 'N/A'}
                </p>
            </div>
  }
  return (
        <li className="pb-3 sm:pb-4 bg-white p-5 rounded-lg flex flex-col gap-2">
            {/*  */}
            <div className="flex justify-between flex-wrap items-center">
                <p className='flex items-center gap-2'>
                    <span className='font-bold text-[#0B0E2C]'>#{bet.game.fightNumber} |</span>
                    {generateHeader()}
                </p>
            </div>
            {/*  */}
            <hr />
            <div className='flex flex-col gap-3'>
                <div className='flex justify-between items-center'>
                    {generateType()}
                    {generateDate()}
                </div>
                {generatePlayingAt(to)}
                {generateStatus()}
                {generateWinner()}

            </div>
        </li>
  )
}
