import React from 'react'
import BaseModal from '../BaseModal'
import { Button } from 'src/components';
import QRCode from "react-qr-code";
interface props {
  isOpen: boolean
  onClose: () => void
  selected: string
}

export function QrModal({ isOpen, onClose, selected }: props) {
  return (
    <BaseModal isOpen={isOpen}>
    <div className="flex flex-col items-center">
    <div className="flex w-full items-center p-4 px-10">
    <h1 className="TitleTextModal w-2/4 lg:w-1/4 ">Retirar</h1>
  </div>
  <div className="w-full flex justify-center flex-col items-center p-12">
  <QRCode value={`${selected}`} className='card p-10'/>
  <p className='my-4 text-[#181E5C] font-bold text-6xl'>{selected}</p>
  {/* <p className='my-2'>{selected}</p> */}
  </div>
      <div className="flex mt-4 mb-4 w-full justify-center">
        <Button classes="bg-[#181E5C] text-white px-4 py-4 rounded-lg mr-12 ml-12 w-full" onClick={onClose} disabled={false} label='IR A LISTA'/>
      </div>
    </div>

  </BaseModal>
  )
}
