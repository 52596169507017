// import { useBetType } from 'src/store';
import { useEffect, useState } from 'react'
import { FaMoneyBillAlt } from 'react-icons/fa'
import { useBalance, useBetType, useSelectedParlay } from 'src/store'
import { useLocation } from 'react-router-dom'
import { useService } from "src/hooks";
import { useSubscription } from '@apollo/client';
import { LISTEN_TO_PLAYER_BALANCE } from 'src/graphql';
import { getStorageValue, APP_STORAGE_KEY } from 'src/utils';

export const TopBar: React.FC = () => {
  const { balance, setBalance } = useBalance()
  const { id: userId } = getStorageValue<{ id: number }>(APP_STORAGE_KEY);

  const { data } = useSubscription(LISTEN_TO_PLAYER_BALANCE, {
    variables: {
      userId
    }
  });

  useEffect(() => {
    if (data) {
      const { listenPlayerBalance: { balance, available, pending } } = data as SubscriptionPlayerBalanceResult;
      setBalance({
        balance, available, pending
      });
    }
  }, [data]);

  const [width, setWidth] = useState(window.innerWidth);
  const { betType, setBetType } = useBetType()
  const { setSelectedParlay } = useSelectedParlay()

  const location = useLocation().pathname
  const playerBalance = useService<Balance>({
    route: 'playerBalance',
    data: res => {
      if (res) {
        setBalance(res);
      }
    }
  });
  useEffect(() => {
    void playerBalance.get();

    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (<>
    {
      width > 1080
        ? <div className="bg-[#ffffff] px-4 py-6 flex justify-between flex-wrap max-w-screen">
          <div className="flex w-2/3">
            <div className="bg-[#ECF2F7] inline-flex items-center BadgeText rounded-full px-6 py-2  "><FaMoneyBillAlt className='mr-2' /> Disponible: {balance?.available}</div>
            <div className="bg-[#ECF2F7] inline-flex items-center BadgeText rounded-full px-6 py-2 ml-4"><FaMoneyBillAlt className='mr-2' /> Pendiente: {balance?.pending}</div>
            <div className="bg-[#ECF2F7] inline-flex items-center BadgeText rounded-full px-6 py-2 ml-4"><FaMoneyBillAlt className='mr-2' /> Balance: {balance?.balance}</div>
          </div>
          {location === '/' && <div className=" w-1/3 flex justify-end items-center">
            <span className='pr-2 TitleText'>TIPO DE JUGADA / </span>

            <label className="flex items-center pr-2">
              <input
                type="radio"
                className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                name={''}
                value={2}
                checked={betType === 2}
                onChange={() => {
                  setBetType(2)
                }
                }
              />
              <span className="ml-3 selectText">Parlay</span>
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                name={''}
                value={1}
                checked={betType === 1}
                onChange={() => {
                  setBetType(1)
                  setSelectedParlay([])
                }
                }
              />
              <span className="ml-3 selectText">Directo</span>
            </label>
          </div>}
        </div>
        : <>
          {location === '/' && <div className=" flex p-2">
            <span className='mr-2 ml-4 TitleText'>TIPO DE JUGADA / </span>

            <label className="flex items-center pr-2">
              <input
                type="radio"
                className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                name={''}
                value={2}
                checked={betType === 2}
                onChange={() => {
                  setBetType(2)
                }}
              />
              <span className="ml-3 selectText">Parlay</span>
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                name={''}
                value={1}
                checked={betType === 1}
                onChange={() => {
                  setBetType(1)
                  setSelectedParlay([])
                }
                }
              />
              <span className="ml-3 selectText">Peleas</span>
            </label>
          </div>}
        </>
    }
  </>
  );
}
