// import { useModal, useSelectedBet, useBetType, useSelectedParlay } from 'src/store';
import { useCurrentAnimation, useSelectedParlay } from 'src/store';
import React from 'react'
import Icon_coock_blue from '../../assets/Icon_coock_blue.svg'
import Icon_coock_yellow from '../../assets/Icon_coock_yellow.svg'
import { CustomCheckbox } from 'src/components'
import { Loader } from 'src/layouts';

interface Props {
  data: Game
}
export function ParlayCard({ data }: Props) {
  const { selectedParlay, setSelectedParlay } = useSelectedParlay();
  const date = new Date(data.startDate);
  const { currentAnimation } = useCurrentAnimation();
  const month = date.toLocaleString("es-ES", { month: "long" });
  const day = date.getDate();
  const year = date.getFullYear();
  const formattedDate = `${month} ${day}, ${year}`;
  const setCurrent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const type = +e.target.value < 3 ? 1 : 2
    if (!selectedParlay.find((item: any) => item.id === data.id && item.type === type)) {
      if (selectedParlay.length < 5) {
        setSelectedParlay([...selectedParlay, { id: data.id, selected: +e.target.value, Game: data, type }])
      }
    } else {
      const newSelectedParlay = selectedParlay.map((item: any) => {
        if (item.id === data.id && item.selected === +e.target.value && item.type === type) {
          return null
        } else if (item.id === data.id && item.type === type) {
          return { ...item, selected: +e.target.value, type }
        }
        return item
      })
      setSelectedParlay(newSelectedParlay.filter((item: any) => item !== null))
    }
  }
  const getSelected = (type: number) => {
    const selected = selectedParlay.find((item: any) => item.id === data.id && item.type === type)
    if (selected) {
      return selected.selected
    }
    return 0
  }
  return (
    <div className="max-w-full overflow-hidden shadow-lg bg-white rounded-md mx-4 h-full ">
     {currentAnimation.id === data.id
       ? <Loader/>
       : <div>
     <div className="px-6 py-4 text-center text-gray-700 bg-gray-200">
        <h1 className="text-lg cardTitle flex justify-between"><span>{formattedDate}</span> <span>#{data.fightNumber}</span></h1>
      </div>
      <div className="p-2 ">
        <div className="flex">
          <div className="w-2/5 px-2 py-4 text-center flexJustifyCenter">
            <img src={Icon_coock_blue} alt="Image 1" className="w-16 h-16 object-cover rounded-full mx-auto" />
            <p className="mt-4 cardRusterName">{data.fighters[0].team.name}({data?.fighters[0]?.favorite ? '-' : '+'}{data?.fighters[0]?.betPercent})</p>
            <div className="flex items-center justify-center h-8 rounded-md mt-2">
              <CustomCheckbox
                name={`firstFighter${data.id}`}
                value={1}
                checked={getSelected(1) === 1}
                onChange={setCurrent}
              />
            </div>
          </div>
          <div className="w-1/5 px-2 py-4 text-center flex justify-center items-center">
            <p className="mt-4 text-[#CE0B0B] font-extrabold text-4xl ">VS</p>
          </div>
          <div className="w-2/5 px-2 py-4 text-center flexJustifyCenter">
            <img src={Icon_coock_yellow} alt="Image 3" className="w-16 h-16 object-cover rounded-full mx-auto" />
            <p className="mt-4 cardRusterName">{data.fighters[1].team.name}({data?.fighters[1]?.favorite ? '-' : '+'}{data?.fighters[1]?.betPercent})</p>
            <div className="flex items-center justify-center h-8 rounded-md mt-2">
              <CustomCheckbox
                name={`secondFighter${data.id}`}
                value={2}
                checked={getSelected(1) === 2}
                onChange={setCurrent}
              />
            </div>
          </div>
        </div>
        {data.morePercentage &&
          <div className="px-2 py-4 flex flex-col items-center flex-wrap">
            <p className="text-[#636363] font-bold">Tiempo:</p>
            <div className="flex">
              <label className="flex items-center pr-2">
                <CustomCheckbox
                  name={`FirstTime${data.id}`}
                  value={3}
                  checked={getSelected(2) === 3}
                  onChange={setCurrent}
                />
                <span className="ml-3 smallerText">A {data.moreTime} (-{data.morePercentage})</span>
              </label>
              <label className="flex items-center">
                <CustomCheckbox
                  name={`SecondTime${data.id}`}
                  value={4}
                  checked={getSelected(2) === 4}
                  onChange={setCurrent}
                />
                <span className="ml-3 smallerText">B {data.moreTime} (-{data.morePercentage})</span>
              </label>
            </div>
          </div>
        }
      </div>
     </div>}
    </div>

  )
}
