interface MainFooterProps {
  withLogo?: boolean
}

export const MainFooter: React.FC<MainFooterProps> = ({ withLogo = true }) => (
    <footer className='relative bottom-0 w-full'>

        <div className="border-gray-200 h-20  bg-left  px-4 lg:px-6 py-2.5 dark:bg-gray-900 bg-no-repeat flex justify-center items-center">
            <span className='text-center text-white'>Copyright © Cock Fight 2019</span>
        </div>
    </footer>
)
