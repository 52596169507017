import Headerv2 from './Headerv2';
import { MainFooter } from './MainFooter';
import { TopBar } from './Topbar';

interface LayoutProps {
  config?: {
    withTopBar?: boolean
    footer?: {
      withLogo?: boolean
    }
  }
}
export const Layout: React.FC<React.PropsWithChildren<LayoutProps>> = (props) => {
  const { children, config } = props;
  const withTopBar = config?.withTopBar;
  const withLogo = config?.footer?.withLogo;

  return (
    <>
      <Headerv2 />
      {withTopBar && <TopBar />}
      <div className="">
        {children}
      </div>
      <MainFooter withLogo={withLogo}/>
    </>
  );
};
