import { useModal, useSelectedBet, useBalance, useCurrentAnimation } from 'src/store';
import React, { useMemo } from 'react'
import BaseModal from './BaseModal'
import ModalCard from './modalCard'
import { useService } from 'src/hooks';
import { Button, Alert } from 'src/components';
import { calculateBet, currency } from 'src/utils';
import { isEmpty } from 'lodash';

const BetModal: React.FC<{ setting?: BetSettings }> = ({ setting }) => {
  const { isModalOpen, setModalOpen } = useModal();
  const { selectedBet } = useSelectedBet();
  const { balance } = useBalance()
  const { currentAnimation } = useCurrentAnimation();
  const [amount, setAmount] = React.useState(0);
  const [disable, setDisable] = React.useState(false);
  const isSettingAvailable = !!setting && !isEmpty(setting?.settings);
  const exceedTheAmountToEarn = useMemo(() => {
    if (selectedBet && isSettingAvailable) {
      const canEarn = calculateBet(null, selectedBet, +amount) as number;
      const maxCanEarn = +setting.settings[0].max;

      return canEarn > maxCanEarn;
    };

    return false;
  }, [selectedBet, isSettingAvailable, amount, setting]);
  const closed = () => {
    if (selectedBet) {
      return selectedBet.game.status === 2 || selectedBet.game.status === 3 || selectedBet.game.status === 4 || selectedBet.game.status === 5 || selectedBet.game.status === 6
    }
    return false
  }
  const bet = useService<{ data: any }>({
    route: 'bets',
    data: res => {
      if (res) {
        setModalOpen(false);
      }
    }
  });
  function disableButton() {
    if (balance) {
      return +balance.available < +amount
    }
    return true
  }
  React.useEffect(() => {
    if (selectedBet) {
      setAmount(+selectedBet.amount.replace('-', '').replace('+', ''))
    }
  }, [selectedBet])
  React.useEffect(() => {
    setDisable(true)
    setTimeout(() => {
      setDisable(false)
    }
    , 5000);
  }, [currentAnimation])

  function saveBet() {
    if (selectedBet) {
      bet.post({
        data: {
          betAmount: amount,
          earnedAmount: calculateBet(null, selectedBet, +amount),
          status: selectedBet.game.status,
          gameMode: 1,
          betDetails: [
            {
              game: selectedBet.game.id,
              betType: 1,
              fighter: selectedBet.game.fighters[selectedBet.to].id,
              favorite: selectedBet.game.fighters[selectedBet.to].favorite,
              betPercent: selectedBet.game.fighters[selectedBet.to].betPercent
            }
          ]
        }
      })
    }
  }
  return (
    <BaseModal isOpen={isModalOpen}>
    <div className="flex flex-col items-center">
    <div className="flex w-full items-center p-4 px-10">
    <h1 className="TitleTextModal w-2/4 lg:w-1/4 ">Directo</h1>
    <hr className="ml-2 border-gray-400 w-2/4 lg:w-3/4" />
  </div>
  {/* display alert if changes */}
  {exceedTheAmountToEarn && <div className='w-full px-4'>
          <Alert kind='error'>
            El monto a ganar supera el límite máximo permitido. No es posible crear esta apuesta.
          </Alert>
        </div>}
  <div className="w-full">

<ul className="divide-y divide-gray-200 mx-5 bg-[#F5F7F9] p-5 rounded overflow-auto max-h-80">
{
  selectedBet ? <ModalCard data={selectedBet.game} to={selectedBet.to}/> : null
}

</ul>
  <div className="grid grid-cols-2 gap-4 p-5 items-center justify-center justify-items-center">
  <div className='flex justify-items-center justify-between flex-wrap items-center'>
    <span className='mdoalNormalText'>Monto de apuesta:</span>
  <input type="number" className="bg-gray-200 rounded-lg p-2 w-1/2 lg:w-1/3  " placeholder='0.00' value={amount} onChange={
    (e) => {
      setAmount(+e.target.value)
    }
  }/></div>
  <div>{
    selectedBet ? <p className='smallText'>Monto a ganar <span className='font-bold '>${calculateBet(null, selectedBet, +amount)}</span></p> : null}

</div>
</div>
<div className="p-5">
  <p className='smallerText font-bold'>
  Monto máximo a ganar:
  </p>
  <p className='font-bold smallerText'>
{selectedBet && isSettingAvailable ? currency(setting.settings[0].max.toString(), 'USD') : null}
  </p>
</div>
  </div>
      <div className="flex mt-4 mb-4 w-full justify-end">
        <button className=" text-[#404F60] px-4 py-4 rounded-lg mr-4" onClick={() => { setModalOpen(false); }}>Cancelar</button>
        <Button classes="bg-[#181E5C] text-white px-4 py-4 rounded-lg mr-4" onClick={saveBet} disabled={disableButton() || bet.serviceLoading === 'post' || disable || closed() || exceedTheAmountToEarn} label='CONFIRMAR'/>
      </div>
    </div>

  </BaseModal>
  )
}

export default BetModal
