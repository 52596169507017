import { isEmpty } from 'lodash';
import { NoResult } from "../NoResults";
import { generateKey } from "src/utils";
import classNames from 'classnames';
import { Loader } from 'src/layouts';
import { usePagination } from 'src/hooks';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

interface Col<T> {
  name: string
  field: string
  class?: string
  icon?: JSX.Element
  render?: (row: Col<T> & { data: T }) => JSX.Element | null
}

interface Props<T> {
  data: T[]
  cols: Array<Col<T>>
  isLoading?: boolean
  titleIcon?: JSX.Element
  loadMoreTitle?: string
  loadMoreIcon?: JSX.Element
  className?: string
  onRowClick?: (data: T) => void
  pagination?: {
    count: number
    perPage?: number
    onServiceCall: (page: number) => void
  }
}

export const CustomTable = function <T>(props: Props<T>) {
  const { data, cols, isLoading, className } = props;
  const classes = classNames('h-screen', className);
  const content = classNames('h-[92%] lg:h-4/5 overflow-x-hidden max-h-[70%] overflow-y-scroll flex flex-col gap-4 lg:block bg-white', {
    'h-fit pt-12': isEmpty(data),
    'h-5/6': props.pagination?.count
  });
  const onClickRow = (value: T) => () => {
    if (props.onRowClick) {
      props.onRowClick(value);
    }
  }
  const { allPages, page, onNext, onPrev, hasMorePages, isAllowedToGoBack } = usePagination({
    count: props.pagination?.count || 0,
    perPage: props.pagination?.perPage
  });

  function next() {
    onNext(props.pagination?.onServiceCall)
  }
  function prev() {
    onPrev(props.pagination?.onServiceCall)
  }

  function renderCardItem(col: Col<T>, d: T) {
    return <div className={classNames('text-left', { 'flex gap-2': col.field === 'id' })}>
      <h5 className={'font-bold flex items-center gap-1'}>{col.icon} {col.name}:</h5> {(d as any)[col.field]}</div>
  }

  return (
    <div className={classes}>
      {/* header */}
      <div className='bg-gray-100 py-4 px-4 mt-2 lg:flex text-left text-md font-bold mb-6 rounded-lg h-fit hidden'>
        {
          cols.filter(c => c.name).map((c, i) => {
            return <div key={c.name + c.field} className={`w-[35%] ${c.class || ''}`}>
              {c.name}
            </div>
          })
        }
      </div>
      {/* content */}
      <div className={content}>
        {!isLoading && !isEmpty(data) && data.map((d, i) => {
          return <button
            onClick={onClickRow(d)}
            type='button'
            key={generateKey(i, Date.now())}
            className='border rounded lg:hidden w-full p-4 flex flex-col gap-4'
          >
             {
              cols.map((t, i) => {
                return <div key={generateKey(t.name, i)} className={`text-sm w-full flex flex-col items-start ${t.class || ''}`}>
                    {t.render ? <div className='text-left'><h5 className='font-bold'>{t.name}: </h5> {t.render({ ...t, data: d })}</div> : renderCardItem(t, d)}
                    {t.field === 'id' && <div className='border-b pt-2 w-full' />}
                  </div>
              })
             }
          </button>
        })}
        {
          !isLoading && !isEmpty(data) && data.map((d, i) => {
            return <button
              type='button'
              className={`w-full border hidden lg:flex p-4 border-b`}
              key={generateKey(i, new Date().getTime())}
              onClick={onClickRow(d)}
            >
              {
                cols.map((t, i) => {
                  return <div key={generateKey(t.name, i)} className={`w-[35%] text-sm flex items-center px-2 justify-start ${t.class || ''}`}>
                    {t.render ? t.render({ ...t, data: d }) : <>{(d as any)[t.field]}</>}
                  </div>
                })
              }
            </button>
          })
        }
        {
          !isLoading && isEmpty(data) && (
            <NoResult />
          )
        }
        {
          isLoading && <Loader />
        }
      </div>
      {!isEmpty(data) && props.pagination?.count && (
          <div className='w-full flex justify-end py-2'>
            <div className='w- flex items-center gap-2'>
              <button disabled={!isAllowedToGoBack} onClick={prev} type='button' className='disabled:opacity-50 p-2 bg-gray-400 disabled:bg-gray-200 rounded-sm disabled:cursor-not-allowed'><FaChevronLeft /></button>
              <div className='w-20 text-center'>{`${page} / ${allPages}`}</div>
              <button disabled={!hasMorePages} onClick={next} type='button' className='disabled:opacity-50 p-2 bg-gray-400 disabled:bg-gray-200 rounded-sm disabled:cursor-not-allowed'><FaChevronRight /></button>
            </div>
          </div>
      )}
    </div>
  )
}
