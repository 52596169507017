import { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { protectedRoutes, globalRoutes } from 'src/router';
import { useIsAuth } from 'src/store';
import { getStorageValue, APP_STORAGE_KEY } from 'src/utils';
import { NotFoundPage } from 'src/pages';
import { ToastContainer } from 'src/container';

function GlobalRoutes() {
  return <Routes>
    {globalRoutes.map(route => <Route key={route.key} path={route.path} element={route.page} />)}
  </Routes>
}

function ProtectedRoutes() {
  return <Routes>
    {protectedRoutes.map(route => <Route key={route.key} path={route.path} element={route.page} />)}
    <Route path='*' element={<NotFoundPage />} />
  </Routes>
}

export function App() {
  const { isAuth, setIsAuth } = useIsAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const storage = getStorageValue<{ token: string }>(APP_STORAGE_KEY);

    if (storage.token) {
      setIsAuth(true);
    } else {
      // if no token then redirect the user to /
      navigate('/');
    }
  }, []);

  return (
    <ToastContainer>
      {isAuth ? <ProtectedRoutes /> : <GlobalRoutes />}
    </ToastContainer>
  );
}
