import { BiLoaderAlt } from "react-icons/bi"

interface LoaderProps {
  text?: string
}

export const Loader: React.FC<LoaderProps> = ({ text = 'Cargando porfavor espere...' }) => {
  return (
        <div className='flex flex-1 justify-center items-center flex-col h-full'>
            <BiLoaderAlt className='animate-spin' size={42}/>
            <p className="py-2">
                {text}
            </p>
        </div>
  )
}
