import { useModal, useSelectedBet, useBetType, useCurrentAnimation } from 'src/store';
import React from 'react'
import Icon_coock_blue from '../../assets/Icon_coock_blue.svg'
import Icon_coock_yellow from '../../assets/Icon_coock_yellow.svg'
import { Button } from 'src/components'
import { Loader } from 'src/layouts';
interface Props {
  data: Game
}

export function BetCard({ data }: Props) {
  const { isModalOpen, setModalOpen } = useModal();
  const { setSelectedBet } = useSelectedBet();
  const { currentAnimation } = useCurrentAnimation();

  const { betType } = useBetType();
  const [bet1, setBet1] = React.useState<string>('');
  const [bet2, setBet2] = React.useState<string>('');
  const date = new Date(data.startDate);
  const month = date.toLocaleString("es-ES", { month: "long" });
  const day = date.getDate();
  const year = date.getFullYear();
  const formattedDate = `${month} ${day}, ${year}`;

  return (
    <div className="max-w-full overflow-hidden shadow-lg bg-white rounded-md mx-4 h-full flexJustifyCenter">
    { currentAnimation.id === data.id
      ? <div>
       <Loader />
      </div>
      : <div>
  <div className="px-6 py-4 text-center text-gray-700 bg-gray-200">
    <h1 className="text-lg cardTitle flex justify-between"><span>{formattedDate}</span> <span>#{data.fightNumber}</span></h1>
  </div>
  <div className="py-4 px-2">
  <div className="flex">
    <div className="w-2/5 px-1 py-4 text-center flexJustifyCenter">
      <img src={Icon_coock_blue} alt="Image 1" className="w-16 h-16 object-cover rounded-full mx-auto"/>
      <p className="mt-4 cardRusterName">{data.fighters[0].team.name}</p>
      <div className="flex items-center justify-center h-8 rounded-md mt-2">
      <div className="bg-[#6EAB5B] w-1/2 h-full rounded-l-md flex justify-center items-center text-white text-sm">{data?.fighters[0]?.favorite ? '-' : '+'}{data?.fighters[0]?.betPercent}</div>
      <input className="bg-gray-300 w-1/2 h-full rounded-r-md p-2 text-xs" type={'number'} min={0} onChange={(e) => {
        setBet1(e.target.value)
      }} value={bet1} disabled={bet2 !== ''}/>
    </div>
    </div>
    <div className="w-1/5 px-1 py-4 text-center flex justify-center items-center">
      <p className="mt-4 text-[#CE0B0B] font-extrabold text-2xl ">VS</p>
    </div>
    <div className="w-2/5 px-1 py-4 text-center flexJustifyCenter">
      <img src={Icon_coock_yellow} alt="Image 3" className="w-16 h-16 object-cover rounded-full mx-auto"/>
      <p className="mt-4 cardRusterName">{data.fighters[1].team.name}</p>
      <div className="flex items-center justify-center h-8 rounded-md mt-2">
      <div className="bg-[#6EAB5B] w-1/2 h-full rounded-l-md flex justify-center items-center text-white text-sm">{data?.fighters[1]?.favorite ? '-' : '+'}{data?.fighters[1]?.betPercent}</div>
      <input className="bg-gray-300 w-1/2 h-full rounded-r-md p-2 text-xs" type={'number'} min={0} onChange={(e) => {
        setBet2(e.target.value)
      }} value={bet2} disabled={bet1 !== ''}/>
    </div>
    </div>
  </div>

  <div className=" py-4 text-center flex  flex-wrap w-full justify-center content-between justify-items-center">
    {/* <input type="number" className="bg-gray-200 rounded-lg p-2 w-full md:w-32  my-2" placeholder='0.00'/> */}
    <Button classes="bg-[#181E5C] text-white py-2 px-4 rounded-full w-full md:w-full my-2" onClick={() => {
      setSelectedBet({
        game: data,
        fighterId: bet1 !== '' ? data?.fighters[0]?.id : data?.fighters[1]?.id,
        to: bet1 !== '' ? 0 : 1,
        amount: bet1 !== '' ? `${data?.fighters[0]?.favorite ? '-' : '+'} ${bet1}` : `${data?.fighters[1]?.favorite ? '-' : '+'} ${bet2}`,
        betType
      })
      setModalOpen(!isModalOpen)
    }} disabled={(!bet1 && !bet2) || (+bet1 === 0 && +bet2 === 0) || (+bet1 < 1 && +bet2 < 1)} label='APOSTAR' />
  </div>
  </div>
  </div>}
</div>

  )
}
