import React from 'react'
import { useSelectedParlay, useModalParlay } from '../../store';
import toast from 'react-hot-toast';

export function RedNote() {
  const { selectedParlay } = useSelectedParlay();
  const { setIsModalOpenParlay, isModalOpenParlay } = useModalParlay();
  const timeParlay = selectedParlay.map((parlay) => parlay.type === 2).filter((parlay) => parlay)
  const directParlay = selectedParlay.map((parlay) => parlay.type === 1).filter((parlay) => parlay)
  const validateParlay = () => {
    if (selectedParlay.length > 1 && selectedParlay.length <= 5) {
      if (timeParlay.length <= directParlay.length) {
        setIsModalOpenParlay(true)
      } else {
        toast.error('Las apuestas a tiempo no deben exceder la cantidad de apuestas a Pelea.');
      }
    }
  }
  return (
    <button type='button'>
    <div className={`h-20 text-white justify-between flex w-1/5 items-center border rounded-lg bg-[#CE0B0B]  bottom-6 right-6 z-30 ${isModalOpenParlay ? 'hidden' : 'fixed'}`} onClick={() => {
      validateParlay()
    }}>
    <div className="rounded-l-lg h-full pl-2 pr-2 flex items-center justify-center w-full lg:w-fit">
      <span className="whiteTitleLarge">
       {selectedParlay.length}
      </span>
    </div>
    <div className=" h-full text-center align-middle hidden items-center pr-6  lg:flex">
      <h1 className="whiteTitle">Cantidad de peleas agregadas</h1>
    </div>
  </div>
    </button>
  )
}
