import { Layout, Loader } from 'src/layouts'
import { TitleWithIcon, CustomTable, SelectableBadge, Button, WithdrawModal, QrModal } from 'src/components'
import { FaCalendarAlt, FaList, FaQrcode } from 'react-icons/fa'
import { useService, type PaginatedBase } from "src/hooks";
import { useEffect, useState } from 'react';
import { CURRENT_WEEK, LAST_WEEK } from 'src/utils';
import classNames from 'classnames';
const layoutConfig = {
  withTopBar: true,
  footer: {
    withLogo: false
  }
}
type SelectedWeek = 'CURRENT_WEEK' | 'LAST_WEEK';
const LAST_WEEK_ID = 2;

export function Transactions() {
  const perPage = 10;

  const [selected, setIsSelected] = useState<SelectedWeek>('CURRENT_WEEK');
  const [list, setList] = useState<any[]>([]);
  const [isOpenQr, setIsOpenQr] = useState<boolean>(false);
  const [isOpenWD, setIsOpenWD] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>('');
  const [pendingBetsCount, setPendingBetsCount] = useState<number>(0);

  const rechargeBalances = useService<PaginatedBase<any[]>>({
    route: 'historyTransfer',
    data: res => {
      if (res) {
        setList(res.data.map(item => {
          return {
            ...item,
            banker:  item?.bettingTable?.name
          }
        }))
        setPendingBetsCount(res.countItem);
      }
    }
  });
  function onSelectWeek(week: SelectedWeek, param?: number) {
    return async () => {
      const query = param ? `?week=${param}` : '';

      await rechargeBalances.get(query);

      setIsSelected(week);
    };
  }
  function renderStatusBadge(data: any) {
    return <div className={classNames('p-1 rounded-md',
      { 'bg-yellow-200': data.status === 'Pendiente' },
      { 'bg-green-300': data.status === 'Completado' },
      { 'bg-red-300': data.status === 3 },
      { 'bg-red-600': data.status === 4 },
      { 'bg-red-800': data.status === 4 }
    )
    }>
      <p>{data.status}</p>
    </div>
  }
  useEffect(() => {
    if (!isOpenWD) {
      rechargeBalances.get(``);
    }
  }, [isOpenWD]);

  const badges = [
    {
      name: 'Semana actual',
      id: CURRENT_WEEK,
      onClick: onSelectWeek('CURRENT_WEEK'),
      selected: selected === CURRENT_WEEK
    },
    {
      id: LAST_WEEK,
      name: 'Semana pasada',
      onClick: onSelectWeek('LAST_WEEK', LAST_WEEK_ID),
      selected: selected === LAST_WEEK
    }
  ];
  async function onLoadMore(page: number) {
    await rechargeBalances.get(`/?page=${page}&perPage=${perPage}&paginate=true`)
  }
  return (
    <Layout config={layoutConfig}>
      <QrModal isOpen={isOpenQr} onClose={() => setIsOpenQr(!isOpenQr)} selected={selectedId}/>
      <WithdrawModal isOpen={isOpenWD} onClose={() => setIsOpenWD(!isOpenWD)}/>
      {(rechargeBalances.serviceLoading === 'get') && <Loader />}
      <div className="p-6 bg-[#F5F7F9] h-full">
        <div className='bg-white pt-8 p-6 h-screen flex flex-col rounded-lg shadow-xl'>
        <div className='flex justify-between lg:items-center h-fit flex-col lg:flex-row gap-2'>
            <TitleWithIcon
              icon={<FaList />}
              title='TRANSACIONES'
            />
            <div className='flex gap-4 bg-white flex-wrap  flex-col-reverse md:flex-row items-end md:items-center'>
            <div className='flex gap-4 bg-white flex-wrap items-center'>
            Buscar por:
              {badges.map(badge => {
                return <SelectableBadge selected={badge.selected} key={badge.id} onClick={badge.onClick} >
                  <p>{badge.name}</p>
                </SelectableBadge>
              })}
            </div>
                      <Button classes="bg-[#181E5C] text-sm font-bold text-white px-4 py-3 px-3 rounded-lg ml-12" onClick={() => setIsOpenWD(true)} disabled={false} label='RETIRAR'/>

            </div>
          </div>
          <div className='overflow-scroll'>
          <CustomTable<TransferBet>
            data={list}
            isLoading={rechargeBalances.serviceLoading === 'get'}
            pagination={{
              count: pendingBetsCount,
              perPage,
              onServiceCall: onLoadMore
            }}
            cols={[
              {
                field: 'id',
                name: 'ID'
              },
              {
                field: 'createdAt',
                name: 'Fecha',
                icon: <FaCalendarAlt />
              },
              {
                field: 'transactionType',
                name: 'Tipo'
              },
              {
                field: 'amount',
                name: 'Monto'
              },
              {
                field: 'status',
                name: 'Estado',
                render: ({ data }) => {
                  return renderStatusBadge(data)
                }
              },
              {
                field: 'qr',
                name: 'Ver código QR',
                render: ({ data }) => {
                  return data?.transactionType === 'Retiro' && data?.status as any === 'Pendiente'
                    ? <div className='flex justify-center w-full'>
                    <div className='bg-[#E1E8EF] p-1'>
                    <FaQrcode className='cursor-pointer text-[2rem] text-[#43515F]' onClick={() => {
                      setSelectedId(data.requestWithdrawalBalance.code)
                      setIsOpenQr(true)
                    }}/>
                    </div>
                  </div>
                    : null
                }
              }
            ]}
          />
          </div>

        </div>
      </div>
    </Layout>
  )
}
