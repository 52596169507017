import { usecoliseum, useGames, useSelectColiseum, useBetType, useSelectedParlay, useSelectedBet, useGlobalMessage } from "src/store"
import NotFound from "src/components/NotFound";
import {
  BetCard,
  ParlayCard,
  RedNote,
  ParlayModal
} from "src/components";
import { Sidebar, Layout, TopBar, Loader } from "src/layouts";
import BetModal from "src/components/BetModal";
import { useEffect, useState } from "react";
import { type Base, useAnimation, useService } from "src/hooks";
import {
  orderBy,
  isEmpty,
  merge
} from "lodash";
import { useSubscription } from '@apollo/client';
import { LISTEN_TO_GAMES, LISTEN_TO_TODAY_GAMES, LIST_TO_FIGHTERS } from 'src/graphql';
import { BET_TYPES } from "src/utils";
import Select from "react-select";

export const HomePage = () => {
  const { coliseum, setcoliseum } = usecoliseum();
  const { setGlobalMessage } = useGlobalMessage();
  const { games, setgames } = useGames();
  const { betType } = useBetType();
  const { selectedParlay, setSelectedParlay } = useSelectedParlay();
  const { selectedBet, setSelectedBet } = useSelectedBet();
  const { selectedColiseum, setSelectedColiseum } = useSelectColiseum();
  const [betSettings, setBetSettings] = useState<BetSettings[]>();
  const [betConfig, setBetConfig] = useState<{ directo: BetSettings, parlay: BetSettings }>({ directo: {} as BetSettings, parlay: {} as BetSettings })

  const { handleAnimation } = useAnimation();

  const coliseumsId = games.map((game) => game.coliseum.id);

  const coliseumFilteredList = coliseum.map((coliseum) => {
    if (coliseumsId.includes(coliseum.id)) {
      return { value: coliseum.id.toString(), label: coliseum.name }
    }
    return { value: '', label: '' }
  }).filter((coliseum) => coliseum.value !== '');

  const coliseums = useService<{ data: any[] }>({
    route: 'coliseums',
    data: res => {
      if (res) {
        setcoliseum(res.data);
      }
    }
  });

  const gamesSvc = useService<Game[]>({
    route: 'todayGames',
    data: res => {
      if (res) {
        setgames(res);
        res[0] ? handleClick(res[0].coliseum.id) : setSelectedColiseum(0);
      }
    }
  });

  const betSettingsSvc = useService<Base<BetSettings[]>>({
    route: 'betSettings',
    data: res => {
      if (!isEmpty(res.data)) {
        const directoConfig = (res.data.find(r => r.gameMode.id === BET_TYPES.directo));
        const parlayCOnfig = (res.data.find(r => r.gameMode.id === BET_TYPES.parlay));

        if (directoConfig) {
          setBetConfig(prev => ({
            ...prev,
            directo: directoConfig
          }));
        }

        if (parlayCOnfig) {
          setBetConfig(prev => ({
            ...prev,
            parlay: parlayCOnfig
          }));
        }

        setBetSettings(res.data);
      }
    }
  });

  useEffect(() => {
    if (isEmpty(betSettings)) {
      betSettingsSvc.get(`?userRole=3`);
    }
  }, [betSettings]);

  const { data } = useSubscription(LISTEN_TO_GAMES);
  const { data: newTodayGame } = useSubscription(LISTEN_TO_TODAY_GAMES);
  const { data: fighter } = useSubscription<FighterSuscriptor>(LIST_TO_FIGHTERS);

  useEffect(() => {
    if (data) {
      const currentGame: Game = data.listenGames;
      const filteredGamesList = games.filter((game) => game.id !== currentGame.id);
      const filteredGames = games.find((game) => game.id === currentGame.id);

      handleAnimation(currentGame.id);

      if (filteredGames) {
        setgames([...filteredGamesList, merge(filteredGames, currentGame)]);

        if (filteredGames.status === 2 || filteredGames.status === 5 || filteredGames.status === 6 || filteredGames.status === 4 || filteredGames.status === 3) {
          const filteredSelected = selectedParlay.filter((parlay) => parlay.id !== currentGame.id);
          setSelectedParlay(filteredSelected);

          if (selectedBet && selectedBet.game.id === currentGame.id) {
            setGlobalMessage('Su apuesta no se puede realizar porque esta pelea se ha cerrado temporalmente.')
          }
        } else {
          if (selectedBet && selectedBet.game.id === currentGame.id) {
            setGlobalMessage('La pelea seleccionada ha cambiado. Por favor revise la lista de peleas nuevamente.')
            setSelectedBet({ ...selectedBet, game: merge(selectedBet.game, currentGame) });
          }
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (newTodayGame && !games.find((g) => g.id === newTodayGame.listenTodayGames.id)) {
      selectedColiseum === 0 && handleClick(newTodayGame.listenTodayGames.coliseum.id)
      setgames([...games, newTodayGame.listenTodayGames]);
    }
  }, [newTodayGame]);

  useEffect(() => {
    if (fighter?.listenFighters && fighter.listenFighters.game) {
      const deliveredGame = games.find(g => g.id === fighter.listenFighters.game.id);

      if (deliveredGame) {
        const filteredGamesList = games.filter((game) => game.id !== deliveredGame.id);

        const { fighters } = deliveredGame;
        const currentFighter = fighters.find(f => f.id === +fighter.listenFighters.id);

        if (currentFighter) {
          const fighterUpdate: Fighter = {
            ...currentFighter,
            betPercent: fighter.listenFighters.betPercent.toString(),
            favorite: fighter.listenFighters.favorite
          };

          const filteredFighter = fighters.filter(f => f.id !== currentFighter.id);

          const updatedList = [...filteredFighter, fighterUpdate];

          const payload = {
            ...deliveredGame,
            fighters: updatedList
          };

          setgames([...filteredGamesList, payload]);

          handleAnimation(deliveredGame.id, { type: 'pulse' });
        }
      }
    }
  }, [fighter])

  const handleClick = (value: number | string) => {
    setSelectedColiseum(+value);
  }

  useEffect(() => {
    void coliseums.get();
    void gamesSvc.get();
  }, []);

  const isLoading = (gamesSvc.serviceLoading === 'get' || coliseums.serviceLoading === 'get' || betSettingsSvc.serviceLoading === 'get');

  return (
    <Layout>
      <BetModal {...{ setting: betConfig.directo }} />
      <ParlayModal {...{ setting: betConfig.parlay }} />

      <div className="flex h-full w-full">
        <Sidebar />
        <div className="flex flex-col flex-1 bg-[#F5F7F9]">
          <TopBar />
          {isLoading && <Loader />}

          {betType === BET_TYPES.parlay && <RedNote />}

          {!isEmpty(games) && !isLoading && <div className="p-4 flex flex-col gap-2 lg:hidden">
            <h1 className="text-gray-600 font-bold">Seleccione un coliseo</h1>
            <Select
              classNames={{
                control: (state) =>
                  state.isFocused ? 'outline-red-600' : 'border-grey-300'
              }}
              isSearchable
              onChange={e => {
                handleClick(e?.value || 0)
              }}
              value={coliseumFilteredList.find((e: any) => +e.value === +selectedColiseum)}
              options={coliseumFilteredList}
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: 'black'
                }
              })}
            />
          </div>}

          {!isEmpty(games) && !isLoading &&
            <div className="relative flex-1">
              <div className="absolute inset-0 overflow-auto">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-6 items-start p-4">
                  {
                    orderBy(games, 'fightNumber', 'asc').map((game) => {
                      if (game.coliseum.id === selectedColiseum && (game.status === 1)) {
                        return betType === BET_TYPES.directo
                          ? <BetCard key={game.id} data={game} />
                          : <ParlayCard key={game.id} data={game} />
                      }
                      return null;
                    })
                  }
                </div>
              </div>
            </div>
          }

          {
            isEmpty(games) && !isLoading && <NotFound />
          }
        </div>
      </div>
    </Layout>
  )
}
