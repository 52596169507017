
interface props {
  label?: string
  name: string
  value: number
  checked: boolean
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void
  classNames?: string
  checkColor?: string
}
export function CustomCheckbox({ label, name, value, checked, onChange, classNames, checkColor }: props) {
  const handleClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  };

  return (
    <label className={`relative inline-block ${classNames || ''}`}>
      <input
        type="checkbox"
        className="sr-only"
        name={name}
        value={value}
        checked={checked}
        onChange={handleClick}
      />
      <div className={`w-6 h-6 rounded-full flxCenterBetween border border-gray-300 transition-colors duration-300 ease-in-out hover:border-[${checkColor || '#CE0B0B'}] focus-within:border-[${checkColor || '#CE0B0B'}]`}>
        <div className={`w-4 h-4 rounded-full m-1 transition-transform duration-300 ease-in-out ${checked ? `transform  bg-[${checkColor || '#CE0B0B'}]` : "bg-none"}`}></div>
      </div>
      {label && (
        <label htmlFor="checkbox" className="ml-2">
          {label}
        </label>
      )}
    </label>
  );
}
