import { env } from "../env";

export const URL = env.REACT_APP_BASE_URL || '';

export const SERVICES = {
  PLAYER: '',
  AUTH: 'auth'
};

export const routes = {
  login: {
    svc: SERVICES.PLAYER,
    route: 'login'
  },
  coliseums: {
    svc: SERVICES.PLAYER,
    route: 'coliseums'
  },
  betsHistory: {
    svc: SERVICES.PLAYER,
    route: 'history-bets'
  },
  historyDetails: {
    svc: SERVICES.PLAYER,
    route: 'history-detail-day'
  },
  todayGames: {
    svc: SERVICES.PLAYER,
    route: 'today-games'
  },
  betPendingPlayer: {
    svc: SERVICES.PLAYER,
    route: 'bet-pending-player'
  },
  rechargeBalances: {
    svc: SERVICES.PLAYER,
    route: 'recharge-balances'
  },
  historyTransfer: {
    svc: SERVICES.PLAYER,
    route: 'history-transfer'
  },
  bettingTables: {
    svc: SERVICES.PLAYER,
    route: 'betting-tables'
  },
  betDetailPlayer: {
    svc: SERVICES.PLAYER,
    route: 'bet-detail-player'
  },
  playerBalance: {
    svc: SERVICES.PLAYER,
    route: 'player-balance'
  },
  bets: {
    svc: SERVICES.PLAYER,
    route: 'bets'
  },
  betSettings: {
    svc: SERVICES.PLAYER,
    route: 'bet-settings'
  }
}

export type RoutesKeys = keyof typeof routes;
