import { Fragment, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import Logo_heahde from 'src/assets/Logo_heahde.svg';
import { useIsAuth } from 'src/store';
import { APP_STORAGE_KEY, deleteStorageValue, getStorageValue, Paths } from 'src/utils';
import { Link } from 'react-router-dom';
import { client } from 'src/grapql.config';
import { SideMenu } from 'src/layouts'

const navigation = [
  { name: 'APUESTAS PENDIENTES', href: Paths.pending, current: false },
  { name: 'HISTORIAL', href: Paths.history, current: false },
  { name: 'TRANSACIONES', href: Paths.Transactions, current: false }
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function Headerv2() {
  const { setIsAuth } = useIsAuth();
  const storage = getStorageValue<{ user: string }>(APP_STORAGE_KEY);
  const [sidebarOpen, setSidebarOpen] = useState(false)

  function onLogout() {
    setIsAuth(false);

    deleteStorageValue(APP_STORAGE_KEY);

    client.resetStore();

    window.location.replace('/');

    window.location.reload();
  }

  return (
    <Disclosure as="nav" className="bg-[#0B0E2C]">
      {({ open }) => (
        <>
          <SideMenu sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} navigation={navigation} onLogout={onLogout}/>
          <div className="mx-auto max-w-full pl-2 sm:pl-6 lg:pl-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="flex flex-1 items-center  sm:items-stretch sm:justify-between h-full w-3/4">
                <div className="flex flex-shrink-0 items-center">
                  <Link to={Paths.home}>
                    <img
                      className="block h-8 w-auto lg:hidden"
                      src={Logo_heahde}
                      alt="Your Company"
                    />
                    <img
                      className="hidden h-8 w-auto lg:block"
                      src={Logo_heahde}
                      alt="Your Company"
                    />
                  </Link>
                </div>
                <div className="hidden sm:ml-6 sm:block ">
                  <div className="flex space-x-4 h-full items-center">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.current ? 'bg-gray-900 text-white' : 'text-white hover:bg-gray-700 hover:text-white',
                          'px-3 py-2 rounded-md text-sm font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center  sm:static sm:inset-auto sm:ml-32 sm:pr-0 w-1/4 h-full ">

                {/* Profile dropdown */}
                <div className='clipPath bg-red-600 h-full w-full flex justify-center items-center '>

                  <Menu as="div" className="relative ml-3 h-full w-full hidden lg:block">
                  <div className='clipPath md:noClip bg-red-600 h-full w-full flex justify-center items-center '>

                    <div className=' h-full w-full flex justify-center items-center '>
                      <Menu.Button className="flex rounded-full  text-md  h-1/2 items-center">

                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-8 w-8 rounded-full text-white hidden lg:block text-white">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                        <span className="text-white font-bold hidden lg:block uppercase">{storage.user || 'Nombre de usuario'}</span>
                      </Menu.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">

                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}

                                onClick={onLogout}>
                                Cerrar sesion
                              </a>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </div>
                    </div>
                  </Menu>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 block lg:hidden text-white"
                    onClick={() => setSidebarOpen(true)}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                  </svg>
                </div>

              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {navigation.map((item) => (
                <Link key={item.name} to={item.href}>
                  <Disclosure.Button
                    className={classNames(
                      item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'block px-3 py-2 rounded-md text-base font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                </Link>

              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
