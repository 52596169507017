import { Layout, Loader } from 'src/layouts'
import { TitleWithIcon, DetailsModal, CustomTable } from 'src/components'
import { FaCalendarAlt, FaList, FaMoneyBill } from 'react-icons/fa'
import { type Base, useService, type PaginatedBase } from "src/hooks";
import { usePendingBets } from 'src/store';
import { useEffect, useState } from 'react';
import { betType } from 'src/utils';

const layoutConfig = {
  withTopBar: true,
  footer: {
    withLogo: false
  }
}

export function PendingPage() {
  const { pendingBets, setPendingBets } = usePendingBets();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [betDetail, setBetDetails] = useState<BetDetails[]>([]);
  const [bet, setBet] = useState<HistoryDetails>({} as HistoryDetails);
  const [pendingBetsCount, setPendingBetsCount] = useState<number>(0);
  const perPage = 10;

  const betPendingPlayer = useService<PaginatedBase<PendingBet[]>>({
    route: 'betPendingPlayer',
    data: res => {
      if (res) {
        setPendingBets(res.data);
        setPendingBetsCount(res.countItem);
      }
    }
  });

  const betDetailPlayer = useService<Base<BetDetails[]>>({
    route: 'betDetailPlayer',
    data: res => {
      setBetDetails(res.data);
    }
  });

  useEffect(() => {
    betPendingPlayer.get(`/?page=1&perPage=${perPage}&paginate=true`);
  }, []);

  async function getBetDetail(data: PendingBet) {
    setIsOpen(true);
    setBet(data);
    // call the service
    await betDetailPlayer.get(`/${data.id}`)
  }

  function handleModal(status: boolean) {
    return () => {
      setBet({} as HistoryDetails);
      setIsOpen(status)
    }
  }

  const modalConfig = {
    isOpen,
    onClose: handleModal(false),
    onAction: handleModal(false),
    selectedBet: {
      bets: betDetail,
      betType: bet.type,
      betAmount: bet.betAmount,
      earnedAmount: bet.earnedAmount,
      date: bet.date
    },
    isLoading: betDetailPlayer.serviceLoading === 'get'
  };

  async function onLoadMore(page: number) {
    await betPendingPlayer.get(`/?page=${page}&perPage=${perPage}&paginate=true`)
  }
  return (
    <Layout config={layoutConfig}>
      <DetailsModal {...modalConfig} />
      {(betDetailPlayer.serviceLoading === 'get') && <Loader />}
      {/* container */}
      <div className="p-6 bg-[#F5F7F9] h-full">
        {/* box */}
        <div className='bg-white pt-8 p-6 h-screen flex flex-col rounded-lg shadow-xl'>
        <div className='pb-4 bg-white'>
            <TitleWithIcon
              icon={<FaList />}
              title='APUESTAS PENDIENTES'
            />
          </div>
          <div>
          <CustomTable<PendingBet>
            data={pendingBets}
            isLoading={betPendingPlayer.serviceLoading === 'get'}
            onRowClick={getBetDetail}
            pagination={{
              count: pendingBetsCount,
              perPage,
              onServiceCall: onLoadMore
            }}
            cols={[
              {
                field: 'id',
                name: 'ID'
              },
              {
                field: 'date',
                name: 'Fecha',
                icon: <FaCalendarAlt />
              },
              {
                field: 'fighter',
                name: 'Peleadores',
                render: ({ data }) => {
                  return <p>{data.fighter}{data.countFighter > 0
                    ? <span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                      (+{data.countFighter})
                    </span>
                    : null}
                  </p>
                }
              },
              {
                field: 'type',
                name: 'Tipo',
                render: ({ data }) => {
                  return <p>{betType[data.type]}</p>
                }
              },
              {
                field: 'betAmount',
                name: 'Apostado',
                icon: <FaMoneyBill />
              },
              {
                field: 'earnedAmount',
                name: 'Ganado',
                icon: <FaMoneyBill />
              }
            ]}
          />
          </div>
        </div>
      </div>
    </Layout>
  )
}
