import { LoginPage, HomePage, PendingPage, History, HistoryDetails, Transactions } from 'src/pages';
import { Paths } from 'src/utils';

export const globalRoutes: Routes[] = [
  {
    path: Paths.home,
    page: <LoginPage />,
    key: 'LoginPage'
  }
];

export const protectedRoutes: Routes[] = [
  {
    path: Paths.home,
    page: <HomePage />,
    label: 'Home',
    key: 'HomePage'
  },
  {
    path: Paths.pending,
    page: <PendingPage />,
    label: 'Pending',
    key: 'PendingPage'
  },
  {
    path: Paths.history,
    page: <History />,
    label: 'History',
    key: 'History'
  },
  {
    path: Paths.Transactions,
    page: <Transactions />,
    label: 'Transactions',
    key: 'Transactions'
  },
  {
    path: Paths.historyDetails,
    page: <HistoryDetails />,
    key: 'history-details'
  }
]

export interface Routes {
  path: string
  label?: string
  page: JSX.Element
  icon?: JSX.Element
  key: string
}
