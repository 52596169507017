import { useState } from "react";

interface UsePaginationOptions {
  count: number
  perPage?: number
}

export function usePagination(options: UsePaginationOptions) {
  const { perPage = 10, count } = options;

  const [page, setPage] = useState(1);

  const allPages = Math.ceil(count / perPage);

  const hasMorePages = page < allPages;
  const isAllowedToGoBack = page > 1;

  const onNext = (callback?: (page: number) => void) => {
    if (hasMorePages) {
      setPage(prev => {
        const currentPage = prev + 1;

        if (callback) {
          callback(currentPage);
        }
        return currentPage;
      })
    }
  };

  const onPrev = (callback?: (page: number) => void) => setPage(prev => {
    if (prev > 1) {
      const prevPage = prev - 1;

      if (callback) {
        callback(prevPage)
      }

      return prevPage;
    }
    return prev
  });

  return {
    onNext,
    onPrev,
    page,
    allPages,
    hasMorePages,
    isAllowedToGoBack
  };
}
