import { useBalance, useCurrentAnimation, useModalParlay, useSelectedParlay } from 'src/store';
import React, { useMemo } from 'react'
import BaseModal from '../BaseModal'
import ModalCard from '../modalCard'
import { useService } from 'src/hooks';
import { Alert, Button } from 'src/components';
import { calculatePairlay, currency } from 'src/utils';
import { isEmpty } from 'lodash';

export const ParlayModal: React.FC<{ setting?: BetSettings }> = ({ setting }) => {
  const { balance } = useBalance()
  const { selectedParlay, setSelectedParlay } = useSelectedParlay();
  const { isModalOpenParlay, setIsModalOpenParlay } = useModalParlay();
  const { currentAnimation } = useCurrentAnimation();
  const [amount, setAmount] = React.useState('');
  const [disable, setDisable] = React.useState(false);
  const bet = useService<{ data: any }>({
    route: 'bets',
    data: res => {
      if (res) {
        setSelectedParlay([])
        setIsModalOpenParlay(false);
      }
    }
  });
  function disableButton() {
    if (balance) {
      return +balance.available < +amount
    }
    return true
  }
  React.useEffect(() => {
    setDisable(true)
    setTimeout(() => {
      setDisable(false)
    }
    , 5000);
  }, [currentAnimation])
  React.useEffect(() => {
    if (selectedParlay.length === 0) {
      setIsModalOpenParlay(false)
    }
  }, [selectedParlay])
  const isSettingAvailable = !!setting && !isEmpty(setting?.settings);

  const currentSetting = useMemo(() => {
    return isSettingAvailable && setting.settings.find(s => s.count === selectedParlay.length)
  }, [setting, isSettingAvailable, selectedParlay]);

  const exceedTheAmountToEarn = useMemo(() => {
    if (currentSetting) {
      const toEarn = +calculatePairlay(selectedParlay, amount);
      const maxToEarn = +currentSetting.max;
      return toEarn > maxToEarn;
    }
    return false;
  }, [currentSetting, amount, selectedParlay])
  function saveBet() {
    bet.post({
      data: {
        betAmount: amount,
        earnedAmount: calculatePairlay(selectedParlay, amount),
        status: 1,
        gameMode: 2,
        betDetails:
          selectedParlay.map((item) => {
            const type = item.selected > 2 ? 2 : 1
            const fighter = type === 1 ? item.Game.fighters[(item.selected - 1)] : undefined
            return {
              game: item.Game.id,
              betType: type,
              fighter: fighter ? fighter.id : undefined,
              favorite: fighter ? fighter.favorite : undefined,
              minTime: item.selected === 4 ? item.Game.lessTime : undefined,
              maxTime: item.selected === 3 ? item.Game.lessTime : undefined,
              betPercent: fighter ? fighter.betPercent : item.Game.morePercentage
            }
          })
      }
    })
  }
  return (
    <BaseModal isOpen={isModalOpenParlay}>
    <div className="flex flex-col items-center">
    <div className="flex w-full items-center p-4 px-10">
    <h1 className="TitleTextModal w-2/4 lg:w-1/4 ">Parlay</h1>
    <hr className="ml-2 border-gray-400 w-2/4 lg:w-3/4" />
  </div>
  {exceedTheAmountToEarn && <div className='w-full px-4'>
          <Alert kind='error'>
            El monto a ganar supera el límite máximo permitido. No es posible crear esta apuesta.
          </Alert>
        </div>}
  <div className="w-full">

<ul className="divide-y divide-gray-200 mx-5 bg-[#F5F7F9] p-5 rounded overflow-auto max-h-80">
{
  selectedParlay.map((item, index) => {
    return (
      <ModalCard data={item.Game} to={item.selected} key={index} type={2} parlay/>
    )
  })
}

</ul>
  <div className="grid grid-cols-2 gap-4  p-5 items-center justify-center justify-items-center">
  <div className='flex justify-items-center justify-between flex-wrap items-center'>
    <span className='mdoalNormalText'>Monto de apuesta:</span>
  <input type="number" className="bg-gray-200 rounded-lg p-2 w-1/2 lg:w-1/3  " placeholder='0.00' value={amount} onChange={
    (e) => {
      setAmount(e.target.value)
    }
  }/></div>
  <div>{
    <p className='smallText'>Monto a ganar <span className='font-bold '>${calculatePairlay(selectedParlay, amount)}</span></p>}

</div>
</div>
<div className="p-5">
  <p className='smallerText font-bold'>
  Monto máximo a ganar <b>({currentSetting ? currentSetting.count : 0})</b> combinaciones:
  </p>
  <p className='font-bold smallerText'>
  {currentSetting ? currency(currentSetting.max.toString(), 'USD') : currency('0', 'USD')}
  </p>
</div>
  </div>
      <div className="flex mt-4 mb-4 w-full justify-end">
        <button className=" text-[#404F60] px-4 py-4 rounded-lg mr-4" onClick={() => { setIsModalOpenParlay(false); }}>Cancelar</button>
        <Button classes="bg-[#181E5C] text-white px-4 py-4 rounded-lg mr-4" onClick={saveBet} disabled={disableButton() || bet.serviceLoading === 'post' || +amount <= 0 || amount === '' || disable || exceedTheAmountToEarn} label='CONFIRMAR'/>
      </div>
    </div>

  </BaseModal>
  )
}
