import { FaArrowLeft, FaCalendarAlt, FaMoneyBill } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { CustomTable, TitleWithIcon, DetailsModal } from 'src/components';
import { type Base, useService, type PaginatedBase } from 'src/hooks';
import { Layout } from 'src/layouts';
import { betType, betStatus, Paths } from 'src/utils';
import classNames from 'classnames';

const layoutConfig = {
  withTopBar: true,
  footer: {
    withLogo: false
  }
}

export const HistoryDetails = () => {
  const [details, setDetails] = useState<HistoryDetails[]>([]);
  const [detailsCount, setDetailsCount] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [betDetail, setBetDetails] = useState<BetDetails[]>([]);
  const [bet, setBet] = useState<HistoryDetails>({} as HistoryDetails);
  const perPage = 10;
  const navigate = useNavigate();

  const detailsSvc = useService<PaginatedBase<HistoryDetails[]>>({
    route: 'historyDetails',
    data: res => {
      setDetails(res.data);
      setDetailsCount(res.countItem)
    }
  });

  const betDetailPlayer = useService<Base<BetDetails[]>>({
    route: 'betDetailPlayer',
    data: res => {
      setBetDetails(res.data);
    }
  });

  const { date } = useParams<{ date: string }>();

  useEffect(() => {
    async function init() {
      if (!date) return;

      await detailsSvc.get(`/${date}/?page=1&perPage=${perPage}&paginate=true`)
    }

    init();
  }, [date]);

  async function onLoadMore(page: number) {
    await detailsSvc.get(`/${date || ''}/?page=${page}&perPage=${perPage}&paginate=true`)
  }

  async function getBetDetail(data: HistoryDetails) {
    setIsOpen(true);
    setBet(data);
    // call the service
    await betDetailPlayer.get(`/${data.id}`)
  }

  function handleModal(status: boolean) {
    return () => {
      setBet({} as HistoryDetails);
      setIsOpen(status)
    }
  }

  const modalConfig = {
    isOpen,
    onClose: handleModal(false),
    onAction: handleModal(false),
    selectedBet: {
      bets: betDetail,
      betType: bet.type,
      betAmount: bet.betAmount,
      earnedAmount: bet.earnedAmount,
      date: bet.date
    },
    isLoading: betDetailPlayer.serviceLoading === 'get'
  };

  function onGoBack() {
    navigate(Paths.history)
  }

  function renderBadge(data: HistoryDetails) {
    return <p className='text-left'>{data.fighter.trimStart()}{data.countFighter > 0
      ? <span className="ml-2 inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
        (+{data.countFighter})
      </span>
      : null}
    </p>
  }

  function renderStatusBadge(data: HistoryDetails) {
    return <div className={classNames('p-1 rounded-md',
      { 'bg-yellow-200': data.status === 1 },
      { 'bg-green-300': data.status === 2 },
      { 'bg-red-300': data.status === 3 },
      { 'bg-red-600': data.status === 4 },
      { 'bg-red-800': data.status === 4 }
    )
    }>
      <p>{betStatus[data.status]}</p>
    </div>
  }

  return (
    <Layout config={layoutConfig}>
      <DetailsModal
        {...modalConfig}
      />
      <div className="p-6 bg-[#F5F7F9] h-full">
        {/* box */}
        <div className='bg-white pt-8 p-6 h-screen flex flex-col rounded-lg shadow-xl'>
          {/* box header */}
          <div className='pb-4 bg-white'>
            <TitleWithIcon
              icon={<button onClick={onGoBack} type='button' className='w-full'>
                <FaArrowLeft />
              </button>}
              title={date || 'Historial'}
            />
          </div>
          {/*  */}
          <CustomTable<HistoryDetails>
            data={details}
            className='h-full'
            isLoading={detailsSvc.serviceLoading === 'get'}
            onRowClick={getBetDetail}
            pagination={{
              count: detailsCount,
              perPage,
              onServiceCall: onLoadMore
            }}
            cols={[
              {
                field: 'id',
                name: 'ID'
              },
              {
                field: 'date',
                name: 'Fecha',
                icon: <FaCalendarAlt />
              },
              {
                field: 'status',
                name: 'Estado',
                render: ({ data }) => {
                  return renderStatusBadge(data)
                }
              },
              {
                field: 'fighter',
                name: 'Peleadores',
                render: ({ data }) => {
                  return renderBadge(data)
                }
              },
              {
                field: 'type',
                name: 'Tipo',
                render: ({ data }) => {
                  return <p>{betType[data.type]}</p>
                }
              },
              {
                field: 'betAmount',
                name: 'Apostado',
                icon: <FaMoneyBill />
              },
              {
                field: 'earnedAmount',
                name: 'Ganado',
                icon: <FaMoneyBill />
              }
            ]}
          />
        </div>
      </div>
    </Layout>
  )
}
