import { usecoliseum, useGames } from 'src/store';
import ItemCard from 'src/components/ItemCard';

export const Sidebar = () => {
  const { coliseum } = usecoliseum();
  const { games } = useGames();
  const coliseumsId = games.map((game) => game.coliseum.id);
  return (
    <div className="bg-gray-200 w-0 lg:w-1/4   h-screen top-0 bottom-0 left-0 py-4 px-0 lg:px-4 max-h-screen overflow-auto">
      <h2 className='text-[#0F1345] font-black italic text-left'>COLISEOS</h2>
      <h3 className='text-[#566779] font-medium font-Montserrat text-sm text-left'>Haga click en un coliseo para ver sus peleas</h3>
      <hr className='w-1/4 bg-[#CE0B0B] h-2'/>
      <div className="hidden md:block p-4">
        {
          coliseum.map((battle) => {
            if (coliseumsId.includes(battle.id)) {
              return (
                <ItemCard
                key={battle.id}
                name={battle.name}
                city={battle.city}
                country={battle.country}
                fights={battle.fights}
                id={battle.id}
                />
              )
            }
            return null
          })
        }
      </div>
    </div>
  )
}
