import { Toaster } from 'react-hot-toast';

interface ToastContainerProps {}

type ToastContainerDef = React.FC<React.PropsWithChildren<ToastContainerProps>>

export const ToastContainer: ToastContainerDef = ({ children }) => {
  return (
    <>
      <Toaster
        position="top-right"
      />
      {children}
    </>
  )
}
