import classNames from "classnames";

interface SelectableBadgeProps {
  selected?: boolean
  onClick: () => void
  className?: string
}

export const SelectableBadge: React.FC<React.PropsWithChildren<SelectableBadgeProps>> = function(props) {
  const { children, onClick, selected, className } = props;

  const classes = classNames(
    'w-fit bg-badge-dark-blue border py-2 px-4 rounded-full focus:outline-none transition duration-300 border-gray-500 text-gray-700',
    { 'bg-transparent ': !selected },
    { 'border-transparent text-white': selected },
    className
  )

  return (
    <button
      type='button'
      className={classes}
      onClick={onClick}
    >
      {children}
    </button>
  )
}
