import React from 'react'
import { BiLoaderAlt } from 'react-icons/bi'

// props
interface Props {
  onClick: () => void
  disabled: boolean
  loading?: boolean
  label: string
  classes?: string
}
export function Button({ onClick, disabled, label, classes, loading }: Props) {
  return (
    <button className={`${disabled ? 'bg-[#ECEFF2] text-gray' : 'bg-[#181E5C] text-white'}  ${classes || ''}`} onClick={onClick} disabled={disabled}>
      {label}
      {loading && <BiLoaderAlt className='animate-spin'/>}
      </button>
  )
}
