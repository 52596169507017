import { FaInfoCircle } from "react-icons/fa";
import { add, getDay, getDate, getMonth } from 'date-fns';
import classNames from 'classnames';

export interface CustomDayCardProps {
  data: HistoryBet
  onClick?: () => void
}

type CustomDayCardComponent = React.FC<React.PropsWithChildren<CustomDayCardProps>>;

const days: Record<number, string> = {
  0: 'Domingo',
  1: 'Lunes',
  2: 'Martes',
  3: 'Miercoles',
  4: 'Jueves',
  5: 'Viernes',
  6: 'Sabado'
} as const;

const months: Record<number, string> = {
  0:'Enero',
  1:'Febrero',
  2:'Marzo',
  3:'Abril',
  4:'Mayo',
  5:'Junio',
  6:'Julio',
  7:'Agosto',
  8:'Septiembre',
  9:'Octubre',
  10:'Noviembre',
  11:'Diciembre'
} as const;

export const CustomDayCard: CustomDayCardComponent = (props) => {
  const { data } = props;
  const date = add(new Date(data.date_query), { days: 1 });
  const currentDay = getDay(date);
  const dayOfTheMonth = getDate(date);
  const currentMonth = getMonth(date);

  const day = days[currentDay];
  const month = months[currentMonth];

  const valueClass = classNames('text-black pt-1 text-xl',
    { 'text-custom-danger': props.data.balance < 0 },
    { 'text-custom-success': props.data.balance > 0 }
  )

  return (
        <button onClick={props.onClick} type="button" key={data.date_query} className='bg-custom-gray overflow-hidden rounded-lg'>
            <div className='px-6 pb-8 pt-4 flex justify-between items-top'>
                <div className='text-left'>
                    <p className='font-bold'>{day}</p>
                    <p className='text-sm'>{dayOfTheMonth} de {month}</p>
                </div>
                {/*  */}
                <FaInfoCircle />
                {/*  */}
            </div>
            <div className='bg-custom-gray-darker py-3 px-6 text-left'>
                <p className='font-bold text-sm'>Balance de</p>
                <p className={valueClass}>
                    {data.balance}
                </p>
            </div>
        </button>
  )
}
