import { FaAt, FaLock } from 'react-icons/fa'
import { BiLoaderAlt } from 'react-icons/bi';
import { Link } from 'react-router-dom'

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

//
import { useIsAuth } from "src/store"
import { LoginHeader, LoginFooter } from "src/layouts";
import Fight from 'src/assets/Gallos.png';
import { useService } from 'src/hooks';
import { setStorageValue, APP_STORAGE_KEY } from 'src/utils';

interface Form {
  username: string
  password: string
  type: number
}

const schema = yup.object({
  username: yup
    .string()
    .required('Este campo es requerido'),
  password: yup.string().required('Este campo es requerido')
});

const resolvedForm = yupResolver(schema);

export const LoginPage = () => {
  const { setIsAuth } = useIsAuth();
  const { register, handleSubmit, formState: { errors } } = useForm<Form>({
    resolver: resolvedForm
  });

  const login = useService<Login>({
    route: 'login',
    data: res => {
      if (res.token) {
        setIsAuth(true);

        setStorageValue(APP_STORAGE_KEY, { token: res.token, user: res.user.username, id: res.user.id });
      }
    },
    options: {
      messages: {
        post: {
          onError: 'Usuario o contraseña incorrectos'
        }
      }
    }
  });

  const isLoading = login.serviceLoading === 'post';

  const submit = handleSubmit(async (data: Form) => {
    data.type = 1
    await login.post({
      data
    });
  })

  return (
    <div className="h-screen bg-red-600 flex flex-col">
      <LoginHeader />
      {/* content */}
      <div className="flex flex-1 items-center justify-center bg-[url('src/assets/background.jpg')] bg-no-repeat px-2 lg:px-12 py-16">
        {/* logo */}
        <div className="hidden md:flex items-end justify-center md:w-1/2">
          <img src={Fight} alt="fight" className="w-full ml-12" />
        </div>

        {/* login container */}
        <div className="w-11/12 md:w-1/2  flex items-center justify-center">
          {/* login box */}
          <div className="flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-lg w-full max-w-md">
            <div className="font-large self-start text-2xl sm:text-2xl text-gray-800 font-Montserrat font-extrabold text-left " >Bienvenido</div>
            <hr className='w-1/6 bg-[#CE0B0B] h-2' />

            <div className="mt-10">
              <form onSubmit={submit}>
                {/* input */}
                <label className="flex flex-col mb-5">
                  <div className="relative">
                    <input
                      {...register('username')}
                      className="bg-[#F5F7F9] text-sm sm:text-base placeholder-gray-500 pl-2 pr-4 rounded-lg  w-full py-4 focus:outline-none focus:border-blue-400"
                      placeholder="Usuario"
                    />
                    <div className="inline-flex items-center justify-center absolute right-0 top-0 h-full w-10 text-gray-400">
                      <FaAt />
                    </div>
                  </div>
                  <p className='h-4 text-red-700'>{errors.username?.message}</p>
                </label>

                {/* password */}
                <label className="flex flex-col mb-5">
                  <div className="relative">
                    <div className="inline-flex items-center justify-center absolute right-0 top-0 h-full w-10 text-gray-400">
                      <FaLock />
                    </div>

                    <input
                      {...register('password')}
                      id="password"
                      type="password"
                      name="password"
                      className="bg-[#F5F7F9] text-sm sm:text-base placeholder-gray-500 pl-2 pr-4 rounded-lg  w-full py-4 focus:outline-none focus:border-blue-400"
                      placeholder="Contraseña"
                    />
                  </div>
                  <p className='h-4 text-red-700'>{errors.password?.message}</p>
                </label>

                <div className="flex items-center mb-6 mt-4">
                  <div className="flex mr-auto">
                    <Link to='/'> {/* TODO: create the route to change pass */}
                      <span className="inline-flex text-xs sm:text-sm text-[#CE0B0B] hover:text-blue-700">Olvidaste tu contraseña ?</span>
                    </Link>
                  </div>
                </div>

                <div className="flex w-full">
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="disabled:opacity-75 disabled:cursor-not-allowed disabled:hover:bg-[#181E5C] flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-[#181E5C] hover:bg-blue-700 rounded-lg py-5 w-full transition duration-150 ease-in"
                  >
                    <span className="mr-2 uppercase">ACCEDER</span>
                    {isLoading && <BiLoaderAlt className='animate-spin'/>}
                  </button>
                </div>
              </form>
            </div>

            <div className="flex justify-center items-center mt-6">

              <Link to='/'> {/* TODO: create route to create account */}
              <div className="ml-2  text-xs text-center ">
                <span className="text-[#465E77]">¿No tienes cuenta? </span>
                <span className="inline-flex items-center font-bold text-black-500 hover:text-blue-700 ">
                  Crea una cuenta
                </span>
              </div>
              </Link>
            </div>
          </div>

        </div>

      </div>
      <LoginFooter />
    </div>
  )
}
