import { useState, useEffect } from 'react';

import { FaHistory } from 'react-icons/fa'
import { Layout, Loader } from 'src/layouts'
import { TitleWithIcon, SelectableBadge, CustomDayCard, NoResult } from 'src/components'
import { CURRENT_WEEK, LAST_WEEK, TREE_WEEKS_BACK } from 'src/utils';
import { useService } from 'src/hooks';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';

type SelectedWeek = 'CURRENT_WEEK' | 'LAST_WEEK' | 'TREE_WEEKS_BACK';
const LAST_WEEK_ID = 2;
const TREE_WEEKS_BACK_ID = 3;

const layoutConfig = {
  withTopBar: true,
  footer: {
    withLogo: false
  }
}

export function History() {
  const navigate = useNavigate();
  const [selected, setIsSelected] = useState<SelectedWeek>('CURRENT_WEEK');
  const [historyBets, setHistoryBets] = useState<HistoryBet[]>([]);
  const historySvc = useService<HistoryBets>({
    route: 'betsHistory',
    data: res => {
      setHistoryBets(res.balances || []);
    }
  });

  useEffect(() => {
    historySvc.get();
  }, []);

  function onSelectWeek(week: SelectedWeek, param?: number) {
    return async () => {
      const query = param ? `?week=${param}` : '';

      await historySvc.get(query);

      setIsSelected(week);
    };
  }

  function onNavigate(date: string) {
    return () => {
      navigate(`/historial/${date}`);
    }
  }

  const badges = [
    {
      name: 'Semana actual',
      id: CURRENT_WEEK,
      onClick: onSelectWeek('CURRENT_WEEK'),
      selected: selected === CURRENT_WEEK
    },
    {
      id: LAST_WEEK,
      name: 'Semana pasada',
      onClick: onSelectWeek('LAST_WEEK', LAST_WEEK_ID),
      selected: selected === LAST_WEEK
    },
    {
      name: 'Hace tres semanas',
      id: TREE_WEEKS_BACK,
      onClick: onSelectWeek('TREE_WEEKS_BACK', TREE_WEEKS_BACK_ID),
      selected: selected === TREE_WEEKS_BACK
    }
  ];

  return (
    <Layout
      config={layoutConfig}
    >
      {/* container */}
      <div className="p-6 bg-[#F5F7F9] h-full">
        {/* box */}
        <div className='bg-white pt-8 p-6 h-screen flex flex-col rounded-lg shadow-xl'>
          {/* box header */}
          <div className='flex justify-between lg:items-center h-fit flex-col lg:flex-row gap-2'>
            <TitleWithIcon
              icon={<FaHistory />}
              title='Historial'
            />
            {/* options */}
            <div className='flex gap-4 bg-white flex-wrap'>
              {badges.map(badge => {
                return <SelectableBadge selected={badge.selected} key={badge.id} onClick={badge.onClick} >
                  <p>{badge.name}</p>
                </SelectableBadge>
              })}
            </div>
          </div>
          {/* content */}
          <div className='flex-1 overflow-y-scroll md:overflow-y-hidden h-full'>
            {/* show the loader */}
            {historySvc.serviceLoading === 'get' && <Loader />}
            {/* show the data */}
            {!isEmpty(historyBets) && historySvc.serviceLoading === 'stopped' && <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-12 py-6'>
              {/* render all the cards */}
              {historyBets.map(bet => {
                return <CustomDayCard
                  onClick={onNavigate(bet.date_query)}
                  key={bet.date_query}
                  {...{ data: bet }} />
              })}
            </div>}

            {(isEmpty(historyBets) || historySvc.apiError) && <NoResult {...{ desc: 'no encontrados', message: 'Resultados del hitorial' }}/>}
          </div>
          {/* end content */}
        </div>
      </div>
    </Layout>
  )
}
